<!--******************** 头部 ********************* -->
<template>
  <div class="header">
    <div class="nav container">
      <img src="../assets/logo.png" alt />
      <ul>
        <li>
          <router-link to="/" exact>首页</router-link>
        </li>
<!--        <li>-->
<!--          <router-link to="/accelerate">加速</router-link>-->
<!--        </li>-->
<!--        <li>-->
<!--          <router-link to="/download">软件下载</router-link>-->
<!--        </li>-->
<!--        <li>-->
<!--          <router-link to="/hardWare">获得硬件</router-link>-->
<!--        </li>-->
<!--        <li>-->
<!--          <router-link to="/recharge">产品购买</router-link>-->
<!--        </li>-->
        <li v-if="!login">
          <span>
            <router-link to="/login">登录</router-link>/
            <router-link to="/register">注册</router-link>
          </span>
        </li>
        <span v-if="login" class="avatar">
          <router-link to="/admin_center">
            <el-dropdown>
              <span class="el-dropdown-link">
                <img class="avatar_img" :src="$store.state.userInfo.avatar" alt />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <span @click="logout">退出登录</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </router-link>
        </span>
      </ul>
    </div>
  </div>
</template>
<script>
import { logoutApi } from "api/AdminCenter";
export default {
  computed: {
    login() {
      return this.$store.state.login;
    }
  },
  methods: {
    //退出登录
    logout() {
      logoutApi().then(res => {
        if (res.code == 0) {
          sessionStorage.removeItem("token");
          this.$store.dispatch({
            type: "changeLoginStatus",
            login: false
          });
          window.location.href = "/";
        }
      });
    }
  }
};
</script>
<style  lang="scss">
.header {
  width: 100%;
  border-bottom: 1px solid #eae3e9;
  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;

    img {
      width: 170px;
      height: 36px;
    }
    .avatar {
      margin-left: 50px;
      .avatar_img {
        width: 44px;
        height: 44px;
      }
    }
    ul {
      display: flex;
      align-items: center;
    }
    li {
      float: left;
      margin-left: 70px;
      a {
        font-weight: bold;
        text-decoration: none;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #202020;
        line-height: 25px;
        position: relative;
      }
      .router-link-active:after {
        content: "";
        width: 100%;
        height: 4px;
        position: absolute;
        background: #517cfc;
        bottom: -23px;
        left: 0;
      }
    }
  }
  button {
    width: 90px;
    height: 24px;
    line-height: 24px;
    color: #fff;
    outline: none;
    border: none;
    border-radius: 2px;
  }
  .login {
    background: #2c4670;
  }
  .reg {
    background: #26ad36;
    margin-left: 10px;
  }
}
</style>
