<!-- *************************** 首页 ****************************** -->
<template>
  <div class="home">
    <!--banner -->
    <div class="banner">
      <div class="content container">
        <div class="box">
          <h4>天翼传奇能永久帮助海外华人解锁全家所有设备、中国应用免除地域限制，尊享线路，自动连接</h4>
          <div class="desc">
            <p>Transgle 最新技术的免翻墙海外回国加速器，一机解锁+加速家中所有的设备，海外华人的必备神器。在海外需要回国访问中国音视频和网站，就用回国加速领先品牌Transgle。畅通访问和加速音视频、国内网站，满足你回国看视频、听音乐和访问网页等各种需求，百万用户的选择。</p>
            <p>APP端支持手机电脑全平台下载使用，好评如潮，专业回国线路，不限流量，稳定快速。</p>
          </div>
          <div class="banner_btn_box">
            <router-link to="/recharge">
              <button class="banner_btn">
                <img style="width:28px" src="../assets/Router.png" alt />
                <span>天翼网盒</span>
              </button>
            </router-link>
            <router-link to="/recharge">
              <button class="banner_btn">
                <img style="width:28px" src="../assets/iphone.png" alt />
                <span>专属APP</span>
              </button>
            </router-link>
          </div>
        </div>
        <div class="banner_right">
          <img src="../assets/banner.png" alt />
          <router-link to="/register">
            <button class="btn_register">免费注册</button>
          </router-link>
        </div>
      </div>
    </div>
    <!--加速 -->
    <JiasuTop />
    <!--下载 -->
    <Download />
    <!--vip-->
    <div class="vip">
      <div class="container">
        <div class="content">
          <h4 class="title">VIP高速套餐</h4>
          <div class="vip_top">
            <p>Transgle网路加速神器直接连接路由器，底层网络直连，无需下载APP，再也不必担心隐私安全问题。SDWAN技术提供流畅用户体验，再也无需国内国外来回切换，让你彻底摆脱VPN。</p>
            <p>Transgle为各位游戏玩家提供游戏专线，独立优化IP池，身在海外玩国服游戏再也不卡机、不延迟、不掉线，完美支持加速王者荣耀、和平精英、英雄联盟 、魔兽世界等国服游戏。在玩游戏的同时还可支持YY语音、听音乐、看视频。</p>
            <div class="vip_box">
              <div class="box">
                <img src="../assets/home_ic_line.png" alt />
                <span>精品路线</span>
              </div>
              <div class="box">
                <img src="../assets/home_ic_hd.png" alt />
                <span>高清播放</span>
              </div>
              <div class="box">
                <img src="../assets/home_ic_service.png" alt />
                <span>客服优先</span>
              </div>
              <div class="box">
                <img src="../assets/home_ic_discounts.png" alt />
                <span>物超所值</span>
              </div>
            </div>
          </div>
          <div class="vip_bottom">
            <div class="vip_b_box_content" v-if="productLists.length>0">
              <div
                v-for="(item,index) in productLists"
                :key="item.order_id"
                @click="choseMeals(index)"
                :class="[{'meals_active':mealsIndex==index},'vip_b_box']"
                :style="{marginRight:(index+1)%4==0?0:'101px'}"
              >
                <h4>{{item.title}}</h4>
                <p class="price price_red" v-if="item.id==13">
                  {{item.price/100}}
                  <span class="currency">{{item.currency}}</span>
                  <!--                  <span class="days">/{{item.days}}天</span>-->
                  <!--                  <span class="year">(年付)</span>-->
                </p>
                <p class="price" v-else-if="item.id==12">
                  {{item.price/100}}
                  <span class="currency">{{item.currency}}</span>
                  <!-- <span class="line_through_price"> 原价 349AUD</span> -->
                  <!--                  <span class="days">/{{item.days}}天</span>-->
                  <!--                  <span class="year">(年付)</span>-->
                </p>
                <p class="price" v-else>                  {{item.price/100}}
                  <span class="currency">{{item.currency}}</span>
<!--                  <span class="days">/30天</span>-->
                </p>
                <p>
<!--                  <span>类型</span>-->
                  {{item.type_text}}
                </p>
                <p>
                  <span>区域</span>
                  {{item.region.region}}
                </p>
                <p
                  class="device"
                >{{ item.device_limit === 0 ? '同时上线多台设备' : '同时上线' + item.device_limit + '台设备'}}</p>
                <p class="device">{{item.description.split("|")[0]}}</p>
                <p class="device">{{item.description.split("|")[1]}}</p>
                <router-link to="/recharge">
                  <button class="buy_now">立即购买</button>
                </router-link>
                <span v-if="item.is_recommend==1" class="intro_logo">推荐</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {getProductListApi} from "api/Recharge";
import Download from "@/views/Download/Intro.vue";
import JiasuTop from "@/views/JiasuTop.vue";
export default {
  name: "Home",
  components: {
    Download,
    JiasuTop
  },
  data() {
    return {
      type: {
        1: "APP客户端",
        2: "加速盒",
        3: "家庭加速盒",
        4: "直连账户",
        5: "账户充值",
        6: "APP+家庭加速盒",
      },
      productLists: [],
      mealsIndex: 0, //当前选择的套餐序列
    };
  },
  methods: {
    //点击选择套餐
    choseMeals(index) {
      this.mealsIndex = index;
    }
  },
  mounted() {
    getProductListApi().then(res => {
      if (res.code == 0) {
        this.productLists = res.data;
        const index = this.productLists.findIndex(
            item => item.is_recommend == 1 //找出推荐
        );
        this.mealsIndex = index;
      }
    });
  }
};
</script>
<style lang='scss'>
.home {
  .banner {
    padding-top: 70px;
    background: linear-gradient(
      180deg,
      rgba(137, 179, 254, 0.2) 0%,
      rgba(24, 0, 255, 0.2) 100%
    );

    .content {
      background-size: cover;
      @include flex_between;
      padding: 52px 0 80px 0;
      .box {
        width: 709px;
        text-align: left;
        h4 {
          font-size: 27px;
          font-weight: 400;
          color: #202020;
          line-height: 67px;
        }
        .banner_btn_box {
          display: flex;
          justify-content: space-around;
          .banner_btn {
            background: #517cfc;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
              margin-top: 3px;
              margin-left: 10px;
            }
          }
        }
        .desc {
          margin: 37px 0 10px 0;
          p {
            font-size: 16px;
            font-weight: 400;
            color: #202020;
            line-height: 34px;
            text-align: justify;
          }
        }
      }
      .banner_right {
        display: flex;
        flex-direction: column;
        .btn_register {
          margin-top: 65px;
          color: #517cfc;
          border: 1px solid #517cfc;
          background:transparent
        }
        img {
          width: 388px;
        }
      }

      button {
        margin-top: 40px;
        width: 190px;
        height: 54px;
        text-align: center;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        line-height: 22px;
      }
    }
  }

  .vip {
    padding: 32px;
    background: #fff;
    .title {
      font-size: 48px;
      font-weight: 400;
      color: #202020;
      line-height: 67px;
    }
    .vip_top {
      padding: 0 70px;
      .title {
        font-size: 48px;
        font-weight: 400;
        color: #202020;
        line-height: 67px;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        color: #4a4a4a;
        line-height: 28px;
        margin: 32px 0;
        text-align: justify;
      }
      .vip_box {
        @include flex_between;
        .buy_now {
          width: 98px;
          height: 38px;
          border-radius: 5px;
          border: 1px solid #517cfc;
          font-size: 14px;
          color: #517cfc;
        }
        .box {
          width: 82px;
          img {
            width: 100%;
            padding-bottom: 20px;
          }
          span {
            font-size: 14px;
            font-weight: 400;
            color: #202020;
            line-height: 20px;
          }
        }
      }
    }
    .intro_box {
      border: 2px solid #517cfc;
    }
    .vip_bottom {
      .vip_b_box_content {
        display: flex;
        flex-wrap: wrap;
        .meals_active {
          h4 {
            font-size: 18px;
          }
          border: 2px solid #517cfc;
        }
        .vip_b_box {
          flex-shrink: 0;
          flex-direction: row;
          flex-wrap: wrap;
          margin-top: 45px;
          box-sizing: border-box;
          margin-right: 101px;
          width: 220px;
          padding: 41px 20px 25px 20px;
          background: #edf9ff;
          border-radius: 4px;
          position: relative;
          &:hover {
            box-shadow: 1px 0 10px 1px rgba(0, 0, 0, 0.1);
          }
          .intro_logo {
            display: inline-block;
            padding: 3px 20px;
            color: #fff;
            font-size: 18px;
            position: absolute;
            right: 0;
            top: 0;
            border-bottom-left-radius: 5px;
            background: #517cfc;
          }
          h4 {
            font-size: 16px;
            font-weight: 500;
            color: #202020;
            line-height: 25px;
          }
          .price {
            font-size: 28px;
            span {
              font-size: 16px;
            }
            .currency {
              color: #517cfc;
            }
            .days {
              color: #4a4a4a;
            }
          }
          .price_red{
            font-size: 26px;
            color: #ff6854;
            span {
              font-size: 14px;
            }
            .currency {
              color: #ff6854;
            }
            .days {
              color: #4a4a4a;
            }
            .year {
              font-size: 8px;
            }
          }
          .line_through_price{
            font-size: 18px !important;
            color: #517cfc !important;
            text-decoration: line-through;
            text-decoration-color: #ff6854;
            display: block;
          }
          .device {
            color: #5a5a5a;
            span {
              color: #517cfc;
            }
          }
          p {
            font-size: 14px;
            font-weight: bold;
            color: #517cfc;
            line-height: 42px;
            span {
              font-size: 14px;
              font-weight: bold;
              color: #5a5a5a;
            }
          }
          button {
            width: 98px;
            height: 38px;
            border-radius: 5px;
            border: 1px solid #517cfc;
            font-size: 14px;
            font-weight: 500;
            color: #517cfc;
            line-height: 20px;
            background: #edf9ff;
          }
        }
      }
    }
  }
}
</style>