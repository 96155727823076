import Vue from 'vue';
import Vuex from 'vuex';
import { getUserInfoApi } from 'api/AdminCenter';
Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		name: 'zhouhong',
		age: 25,
		login: Boolean(sessionStorage.getItem('token')),
		userInfo: {},
	},
	mutations: {
		getUserInfo(state, userInfo) {
			state.userInfo = userInfo;
		},
		changeName(state, name) {
			state.name = name;
		},
		changeAge(state, payload) {
			state.age = payload.age;
		},
		changeLoginStatus(state, payload) {
			state.login = payload.login;
		},
	},
	actions: {
		getUserInfo({ commit }) {
			getUserInfoApi().then((res) => {
				if (res.code == 0) {
					commit('getUserInfo', res.data);
				}
			});
		},
		changeName({ commit }, name) {
			commit('changeName', name);
		},
		changeAge({ commit }, age) {
			commit('changeAge', age);
		},
		changeLoginStatus({ commit }, login) {
			commit('changeLoginStatus', login);
		},
	},
	modules: {},
});
