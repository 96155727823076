import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import RouterGuard from './routerGuard';

Vue.use(VueRouter);

const routes = [
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/Login.vue'),
		meta: { title: '登录' },
	},
	{
		path: '/login/get_back_pwd',
		name: 'BackPassword',
		component: () => import('../views/BackPassword.vue'),
		meta: { title: '找回密码' },
	},

	{
		path: '/register',
		name: 'register',
		component: () => import('../views/Register.vue'),
		meta: { title: '注册' },
	},
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: { title: '首页' },
	},

	{
		path: '/accelerate',
		name: 'accelerate',
		component: () => import('../views/Accelerate.vue'),
		meta: { title: '加速' },
	},
	{
		path: '/download',
		meta: { title: '下载工具' },
		component: () => import('../views/Download/Index.vue'),
		redirect: '/download/android',
		children: [
			{
				path: '/download/android',
				component: () => import('../views/Download/Android.vue'),
				meta: { title: '安卓下载' },
			},
			{
				path: '/download/buy',
				component: () => import('../views/Download/Buy.vue'),
				meta: { title: '购买' },
			},
		],
	},
	{
		path: '/recharge',
		name: 'Recharge',
		component: () => import('../views/Recharge.vue'),
		meta: { title: '产品购买' },
	},
	{
		path: '/hardWare',
		name: 'hardWare',
		component: () => import('../views/GetHardware.vue'),
		meta: { title: '获取硬件' },
	},
	{
		path: '/admin_center/member_recharge',
		name: 'member_recharge',
		component: () => import('../views/AdminCenter/MemberRecharge.vue'),
		meta: { title: '会员充值' },
	},
	{
		path: '/admin_center',
		component: () => import('../views/AdminCenter/index.vue'),
		meta: { title: '个人中心' },

		redirect: '/admin_center/preview',
		children: [
			{
				path: 'preview',
				component: () => import('../views/AdminCenter/Preview.vue'),
				meta: { title: '我的产品' },
			},
			{
				path: 'myorder',
				component: () => import('../views/AdminCenter/MyOrder.vue'),
				meta: { title: '我的订单' },
			},
			{
				path: 'address',
				component: () => import('../views/AdminCenter/Address.vue'),
				meta: { title: '配送地址' },
			},
			{
				path: 'certification',
				component: () => import('../views/AdminCenter/Certification.vue'),
				meta: { title: '实名认证' },
			},
			{
				path: 'email_bind',
				component: () => import('../views/AdminCenter/EmailBind.vue'),
				meta: { title: '邮箱绑定' },
			},
			{
				path: 'change_pwd',
				component: () => import('../views/AdminCenter/ChangePwd.vue'),
				meta: { title: '修改密码' },
			},
			{
				path: 'record',
				component: () => import('../views/AdminCenter/Record.vue'),
				meta: { title: '分销记录' },
			},
			{
				path: 'wiki',
				component: () => import('../views/AdminCenter/Wiki.vue'),
				meta: {title: '帮助文档'},
			},
		],
	},
	{
		path: '/information',
		meta: { title: '下载工具' },
		component: () => import('../views/Information/Index.vue'),
		redirect: '/',
		children: [
			{
				path: '/information/cookie',
				component: () => import('../views/Information/Cookie.vue'),
				meta: { title: 'Cookie' },
			},
			{
				path: '/information/privacy_policy',
				component: () => import('../views/Information/PrivacyPolicy.vue'),
				meta: { title: '隐私协议' },
			},
			{
				path: '/information/fair_go_policy',
				component: () => import('../views/Information/FairGoPolicy.vue'),
				meta: { title: 'FairGoPolicy' },
			},
			{
				path: '/information/consumer_terms',
				component: () => import('../views/Information/ConsumerTerms.vue'),
				meta: { title: '服务条款' },
			},
		],
	},
];

const router = new VueRouter({
	routes,
});
RouterGuard(router);

export default router;
