import store from '../store';
export default (router) => {
	router.beforeEach((to, from, next) => {
		// 让页面回到顶部
		document.documentElement.scrollTop = 0;
		//刷新如果已登录获取---重新更新用户信息
		if (JSON.stringify(store.state.userInfo) == '{}' && store.state.login) {
			store.dispatch({
				type: 'getUserInfo',
			});
		}
		next();
	});
	router.afterEach((to) => {
		//设置窗口title
		document.title = to.query.title || to.meta?.title || '';
	});
};
