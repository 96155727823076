import Ajax from './http';

//获取用户信息
export function getUserInfoApi(values) {
	return Ajax.post('/user/info', values);
}

//获取产品列表
export function getMyProductApi() {
	return Ajax.post('/user/product/info');
}
//获取订单列表
export function getMyOrderListApi(values) {
	return Ajax.post('/order/list', values);
}

//获取分销记录列表
export function getSaleListApi(values) {
	return Ajax.post('/user/distribution/list', values);
}
//发送邮箱验证码
export function sendEmailCodeApi(values) {
	return Ajax.post('/user/update/send_email_code', values);
}

//绑定、修改邮箱验证码
export function bindEmailApi(values) {
	return Ajax.post('/user/update/email', values);
}
//获取邀请吗
export function getInviteApi() {
	return Ajax.post('/user/invite/code_url');
}
//退出登录
export function logoutApi() {
	return Ajax.post('/user/logout');
}
//搜索地址 已禁用google maps api - 2021-09-15
// export function getAddressListApi(values) {
// 	return Ajax.post('/user/address/search', values);
// }
//获取地址
export function getAddressInfoApi(values) {
	return Ajax.post('/user/address/info', values);
}

//创建/修改地址
export function saveAddressApi(values) {
	return Ajax.post('/user/address/update', values);
}
//修改密码---发送验证码
export function changePwdSendCodeApi(values) {
	return Ajax.post('/user/update/send_phone_code', values);
}
//修改密码
export function changePwdApi(values) {
	return Ajax.post('/user/update/password', values);
}

//用户实名认证信息
export function getCertificationInfoApi(values) {
	return Ajax.post('/user/certification/info', values);
}

//用户实名认证上传图片
export function certificationUploadApi(values) {
	return Ajax.post('/user/certification/upload', values);
}