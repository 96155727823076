import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import {
	Form,
	FormItem,
	Input,
	Button,
	Checkbox,
	Row,
	Col,
	Select,
	Option,
	Tabs,
	TabPane,
	Message,
	Menu,
	MenuItem,
	Aside,
	Container,
	TableColumn,
	Table,
	Radio,
	RadioGroup,
	MessageBox,
	Loading,
	Icon,
	Pagination,
	Dropdown,
	DropdownMenu,
	DropdownItem,
	Autocomplete,
	Upload
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VuCountryIntl from 'vue-country-intl';
import 'vue-country-intl/lib/vue-country-intl.css';
import './styles/common.scss';
Vue.component(VuCountryIntl.name, VuCountryIntl);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Button);
Vue.use(Checkbox);
Vue.use(Row);
Vue.use(Col);
Vue.use(Select);
Vue.use(Option);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Aside);
Vue.use(Container);
Vue.use(TableColumn);
Vue.use(Table);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Icon);
Vue.use(Pagination);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Autocomplete);
Vue.use(Upload);
Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$message = Message;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app');
